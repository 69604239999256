import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import { HelmetProvider } from 'react-helmet-async';
import AppRoutes from './AppRoutes';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <HelmetProvider>
    <div className="App"> 
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
    <Router>
      <ScrollToTop />
     <Navbar />
     <AppRoutes />
     <Footer />

     </Router>
     </ThemeProvider>
     </div>
     </HelmetProvider>
  );
}

export default App;
