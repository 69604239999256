import React from 'react';
import { Container, Typography, Grid, Box, Button, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import { DeviceFrameset } from 'react-device-frameset'; // Ensure this is the correct import
import { Parallax } from 'react-scroll-parallax';
import useMediaQuery from '@mui/material/useMediaQuery';

const projects = [
  {
    title: <><Typography variant="h3">All PC Repair</Typography></>,
    description: (
      <>
        <Typography variant="body1" sx={{ color: '#ccc', background: 'black', padding: 5, borderRadius: 2 }}>
          All PC Repair is a Virginia-based IT solutions provider that offers a wide range of services tailored for both residential and commercial clients. Their expertise covers onsite computer repairs, system upgrades, and comprehensive IT management services for businesses in the Hampton Roads area.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          All PC Repair focuses on helping businesses streamline their operations, boost productivity, and stay secure through services like web development, cybersecurity monitoring, and more.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          They cater to small and medium-sized businesses, ensuring that their IT systems are optimized for efficiency and security. Additionally, their residential services make it easy for individuals to resolve issues with PCs, laptops, and other devices through fast and reliable onsite repairs.
        </Typography>
      </>
    ),
    link: 'https://allpcrepairva.com',
    mobileScreenshot: 'allpcrepair-mobile-view.png',
  },
  
  
  {
    title: <><Typography variant="h3">Family Reunite Network</Typography></>,
    description: (
      <>
        <Typography variant="body1" sx={{ color: '#ccc', background: 'black', padding: 5, borderRadius: 2 }}>
          Family Reunite Network is an organization led by T.J. Scott, dedicated to addressing issues surrounding family reunification and immigration. The network offers support to individuals and families facing the challenges of family separation, particularly within the Hampton Roads area.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          Focused on assisting immigrant families, Family Reunite Network provides resources and guidance to help families navigate complex legal and social systems. The platform serves as both an advocacy and support tool for families, aiming to foster meaningful connections.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          The network promotes awareness of the struggles of family separation due to immigration issues and works to assist those in need of reunification.
        </Typography>
      </>
    ),
    link: 'https://familyreunitenetwork.com',
    mobileScreenshot: 'familyreunitenetwork-mobile-view.png',
  },
  
  {
    title: <><Typography variant="h3">Deseo Media Company</Typography></>,
    description: (
      <>
        <Typography variant="body1" sx={{ color: '#ccc', background: 'black', padding: 5, borderRadius: 2 }}>
          Deseo Media Company is a creative media agency based in Virginia Beach, focused on media production, branding, and business development. Their mission is to inspire hope and cultivate community through authenticity and creative storytelling.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2}}>
          They offer a wide range of services, including podcasts, videography, photography, digital marketing, and web development, all aimed at helping businesses grow and connect with their audiences in a meaningful way.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          Deseo Media Company works with various clients, from small businesses to larger brands, and is committed to delivering quality media solutions that foster meaningful connections between brands and their communities.
        </Typography>
      </>
    ),
    link: 'https://deseomediacompany.com',
    mobileScreenshot: 'deseomediacompany-mobile-view.png',
  },

  {
    title: <><Typography variant="h3">Living Faith Christian Fellowship</Typography></>,
    description: (
      <>
        <Typography variant="body1" sx={{ color: '#ccc', background: 'black', padding: 5, borderRadius: 2 }}>
          Living Faith Christian Fellowship is a welcoming community church located in Fernley, NV, focused on fostering faith and building a strong sense of community.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          The church offers regular services, events, and programs designed for individuals of all ages to come together and grow spiritually.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          With a commitment to supporting those seeking spiritual guidance, the church provides a place for worship, learning, and community connection.
        </Typography>
      </>
    ),
    link: 'https://www.livingfaithchristianfellowship.com',
    mobileScreenshot: 'living-faith-christian-fellowship-mobile-view.png',
  },
  
  {
    title: <><Typography variant="h3">Valet Ninjas</Typography></>,
    description: (
      <>
        <Typography variant="body1" sx={{ color: '#ccc', background: 'black', padding: 5, borderRadius: 2 }}>
          Valet Ninjas is a professional valet parking service offering efficient and reliable solutions for events and venues.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          Specializing in exceptional customer service, Valet Ninjas ensures that parking logistics are handled smoothly and securely for clients.
        </Typography>
        <Typography variant="body1" sx={{ color: '#ccc', marginTop: '10px', background: 'black', padding: 5, borderRadius: 2 }}>
          They provide valet services for a variety of events, including corporate gatherings, weddings, and private functions.
        </Typography>
      </>
    ),
    link: 'https://www.valetninjas.website',
    mobileScreenshot: 'valet-ninjas-mobile-view.png',
  },
  
];

const Portfolio = () => {
  // Responsive breakpoints
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{ backgroundColor: '#121212', padding: '50px 0', color: '#fff' }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          sx={{ marginBottom: '40px' }}
        >
          Featured Websites
        </Typography>

        <Grid container spacing={20}>
          {projects.map((project, index) => (
            <Grid item xs={12} key={`${project.title}-${index}`}>
              <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    backgroundColor: '#1e1e1e',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '20px',
                  }}
                >
                  <Parallax speed={10}>
                    <Box
                      sx={{
                        backgroundColor: '#1e1e1e',
                        borderRadius: '10px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        maxWidth: isMobile ? '300px' : '400px', // Responsive width adjustment
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <DeviceFrameset
                        device="iPhone X"
                        color="gold"
                        portrait={true}
                      >
                        <img
                          src={project.mobileScreenshot}
                          alt={`${project.title} Mobile`}
                          style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                        />
                      </DeviceFrameset>
                    </Box>
                  </Parallax>
                  {/* Description and Button */}
                  <Box sx={{ padding: '20px', textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#fff' }}>
                      {project.title}
                    </Typography>
                    <Divider sx={{marginBottom: 2}}/>
                    <Divider sx={{marginBottom: 2}}/>
                    <Typography variant="body1" sx={{ color: '#ccc', marginBottom: '15px' }}>
                      {project.description}
                    </Typography>
                    <Divider sx={{marginBottom: 2}}/>
                    <Divider sx={{marginBottom: 2}}/>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'black',
                        fontFamily: 'Crypto-Crash-Font',
                        fontSize: '1.5rem',
                        color: 'white',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #ffffff 30%, #fff 90%)',
                          boxShadow: '0px 0px 2px rgba(255, 255, 231, 1)',
                          color: 'black',
                        },
                      }}
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit Site
                    </Button>
                  </Box>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
};

export default Portfolio;
