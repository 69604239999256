import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, IconButton } from '@mui/material';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DropdownMenu from './DropdownMenu';
import logo from '../assets/neal-frazier-tech-logo.png';
import { Link } from 'react-router-dom';

const Logo = styled('img')({
  width: '50px',
  height: 'auto',
  marginLeft: '20px',
  marginTop: 7,
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: '64px',
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  boxShadow: 'none',
  position: 'fixed',
  marginTop: '20px',
  left: '50%',  // Shift to the center of the screen horizontally
  transform: 'translateX(-50%)',  // Center it by adjusting half its own width
  transition: 'top 0.3s',
  borderRadius: '60px',
  width: '90%', // Default width
  [theme.breakpoints.up('md')]: {
    width: '70%',  // Width for medium and larger screens
  },
}));


const Navbar = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setScrollDirection(currentScrollY > prevScrollY ? 'down' : 'up');
    setPrevScrollY(currentScrollY);
  }, [prevScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <StyledAppBar
      sx={{ top: scrollDirection === 'down' ? '-84px' : '0', boxShadow: '0px 0px 10px rgba(255, 255, 231, 0.5)' }} // Adjust the height if necessary
    >
      <Toolbar>
        <Link to="/">
        <Logo src={logo} alt="Logo" />
        </Link>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          sx={{ marginLeft: 'auto', marginRight: '10px' }}
        >
          {openMenu ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <DropdownMenu
          open={openMenu}
          onClose={handleMenuClose}
        />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
