import React, { useState, useEffect } from 'react';
import logo from './assets/neal-frazier-tech-logo.png';
import { Helmet } from 'react-helmet-async';
import { Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

// Styled Components
const Container = styled('div')({
  backgroundColor: '#000',
  minHeight: '100vh',
  padding: '20px',
  color: '#fff',
  fontFamily: '"Orbitron", monospace',
  paddingTop: '100px',
});

const Header = styled('div')({
  textAlign: 'center',
  marginBottom: '40px',
});

const SearchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '40px',
});

const SearchInput = styled(TextField)({
  width: '60%',
  '& .MuiInputBase-root': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#fff',
    },
    '&:hover fieldset': {
      borderColor: '#fff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
    },
  },
});

const BlogsList = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const BlogItem = styled(motion.div)({
  backgroundColor: '#111',
  borderRadius: '10px',
  margin: '20px',
  padding: '20px',
  width: '280px',
  boxShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
  textAlign: 'center',
});

const BlogImage = styled('img')({
  width: 'auto',
  height: '150px',
  objectFit: 'cover',
  borderRadius: '8px',
  marginBottom: '15px',
});

const BlogTitle = styled('a')({
  color: '#fff',
  textDecoration: 'none',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: '10px',
  display: 'block',
  '&:hover': {
    color: '#00e676',
  },
});

const BlogSummary = styled(Typography)({
  color: '#aaa',
  fontSize: '0.9rem',
  marginBottom: '10px',
});

const NoBlogsFound = styled(Typography)({
  textAlign: 'center',
  fontSize: '1.2rem',
  color: '#fff',
});

const ReadMoreLink = styled('a')({
  color: '#00ffe7',
  textDecoration: 'none',
  fontWeight: 'bold',
  '&:hover': {
    color: '#fff',
  },
});


function NFTBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    // Fetch blogs data
    fetch('/data/blogs.json')
      .then((response) => response.json())
      .then((data) => {
        setBlogs(data);
        setFilteredBlogs(data);
      })
      .catch((error) => console.error('Error fetching blogs:', error));
  }, []);

  useEffect(() => {
    // Filter blogs based on search query
    const normalizedQuery = searchQuery.toLowerCase();
    const filtered = blogs.filter((blog) => {
      return (
        blog.title.toLowerCase().includes(normalizedQuery) ||
        blog.summary.toLowerCase().includes(normalizedQuery)
      );
    });
    setFilteredBlogs(filtered);
  }, [searchQuery, blogs]);

  const handleVoiceSearchClick = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setSearchQuery(transcript);
        setIsListening(false);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
        alert('There was an error with voice search. Please try again.');
      };

      recognition.start();
    } else {
      alert('Speech recognition is not supported in this browser.');
    }
  };

  return (
    <Container>
      <Helmet>
        <title>NFT Blogs | Digital Tips & Insights by Neal Frazier</title>
        <meta
          name="description"
          content="Explore NFT blogs by Neal Frazier, offering insights and tips on digital assets, blockchain, and the future of NFTs. Use voice or text search to filter posts."
        />
        <meta
          name="keywords"
          content="NFT, Blogs, Digital Tips, Blockchain, Neal Frazier, Insights, Voice Search"
        />
        <meta property="og:title" content="NFT Blogs | Digital Tips & Insights by Neal Frazier" />
        <meta property="og:description" content="Discover digital tips and insights on NFTs from Neal Frazier." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://nealfrazier.tech/nftblogs" />
        <meta property="og:image" content="https://nealfrazier.tech/images/nft-logo.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="NFT Blogs | Digital Tips & Insights by Neal Frazier" />
        <meta name="twitter:description" content="Discover digital tips and insights on NFTs from Neal Frazier." />
        <meta name="twitter:image" content="https://nealfrazier.tech/images/nft-logo.png" />
      </Helmet>


      <Header>
        <Typography variant="h2" sx={{ color: '#fff', fontFamily: '"Orbitron", monospace', paddingTop: 7 }}>
          NFT Blogs: Digital Tips & Insights
        </Typography>



        <Typography variant="h6" sx={{ color: '#ccc', marginTop: '20px', fontFamily: '"Orbitron", monospace' }}>
          Filter through the blog posts by typing or using voice search.
        </Typography>
      </Header>

      <SearchContainer>
        <SearchInput
          variant="outlined"
          placeholder="Search blogs..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleVoiceSearchClick} disabled={isListening}>
                  {isListening ? (
                    <MicOffIcon sx={{ color: '#fff' }} />
                  ) : (
                    <MicIcon sx={{ color: '#fff' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#fff' }} />
              </InputAdornment>
            ),
          }}
        />
      </SearchContainer>

      {/* Blog List Rendering */}
      {filteredBlogs.length > 0 ? (
        <BlogsList>
          {filteredBlogs.map((blog) => (
            <BlogItem
              key={blog.id}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <BlogImage src={logo} alt={blog.title} />
              <BlogTitle href={blog.url} target="_blank" rel="noopener noreferrer">
                {blog.title}
              </BlogTitle>
              <BlogSummary>{blog.summary}</BlogSummary>
              <ReadMoreLink href={blog.url} target="_blank" rel="noopener noreferrer">
                Read More
              </ReadMoreLink>
            </BlogItem>
          ))}
        </BlogsList>
      ) : (
        <NoBlogsFound>No blogs found matching your search.</NoBlogsFound>
      )}
    </Container>
  );
}

export default NFTBlogs;
