import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faGithub,
  faDiscord,
  faSoundcloud,
  faXTwitter,
  faCodepen,
  faInstagramSquare
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const FooterContainer = styled.footer`
  padding: 20px;
  text-align: center;
  z-index: 9999;
`;

const SocialLink = styled.a`
  margin: 0 10px;
  color: #333; // Change as needed
  font-size: 24px; // Adjust size as needed

  &:hover {
    color: #007bff; // Change hover color as needed
  }
`;

const TimeAndDate = styled.div`
  margin-top: 20px;
  font-size: 16px;
`;

const Copyright = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const currentDate = new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <FooterContainer>
      {/* Social Icons */}
      <SocialLink href="https://twitter.com/NealFrazierTech" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faXTwitter} />
      </SocialLink>
      <SocialLink href="https://www.linkedin.com/in/NealFrazier" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </SocialLink>
      <SocialLink href="https://github.com/1nc0gn30" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faGithub} />
      </SocialLink>
      <SocialLink href="mailto:business@nealfrazier.tech" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faEnvelope} />
      </SocialLink>
      <SocialLink href="https://discord.gg/qwBtx9NY" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faDiscord} />
      </SocialLink>
      <SocialLink href="https://soundcloud.com/1nc0gn30" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faSoundcloud} />
      </SocialLink>
      <SocialLink href="https://codepen.io/Neal-Frazier" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faCodepen} />
      </SocialLink>
      <SocialLink href="https://instagram.com/nealfraziertech" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagramSquare} />
      </SocialLink>
      
      {/* Time and Date */}
      <TimeAndDate>
        {currentTime} - {currentDate}
      </TimeAndDate>

      {/* Copyright */}
      <Copyright>
        &copy; {currentYear} Neal Frazier Tech. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;