import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NFTBlogs from './NFTBlogs';
import MasteringReactBlog from './blogs/MasteringReactBlog';
import SEOChecklistBlog from './blogs/SEOChecklistBlog';
import SecureWebAppsBlog from './blogs/SecureWebAppsBlog';
import FutureWebDevTrendsBlog from './blogs/FutureWebDevTrendsBlog';
import SEOStrategiesBlog from './blogs/SEOStrategiesBlog';
import WebDevFundamentalsBlog from './blogs/WebDevFundamentalsBlog';
import CSSGridsBlog from './blogs/CSSGridsBlog';
import JavaScriptCheatSheetBlog from './blogs/JavaScriptCheatSheetBlog';
import UXDesignPrinciplesBlog from './blogs/UXDesignPrinciplesBlog';
import WebAccessibilityBlog from './blogs/WebAccessibilityBlog';
import AdvancedCSSTechniquesBlog from './blogs/AdvancedCSSTechniquesBlog';
import CybersecurityThreatsBlog from './blogs/CybersecurityThreatsBlog';
import ImplementingDarkModeBlog from './blogs/ImplementingDarkModeBlog';
import BasicsOfCryptographyBlog from './blogs/BasicsOfCryptographyBlog';
import IntroToStoicismBlog from './blogs/IntroToStoicismBlog';
import FruitarianismGuideBlog from './blogs/FruitarianismGuideBlog';
import ResponsiveWebDesignBlog from './blogs/ResponsiveWebDesignBlog';
import EthicalHackingOverviewBlog from './blogs/EthicalHackingOverviewBlog';
import MinimalistDesignBlog from './blogs/MinimalistDesignBlog';
import BlockchainForWebDevBlog from './blogs/BlockchainForWebDevBlog';
import WebScrapingPythonBlog from './blogs/WebScrapingPythonBlog';
import OpenSourceSoftwareBlog from './blogs/OpenSourceSoftwareBlog';
import ColorTheoryWebDesignBlog from './blogs/ColorTheoryWebDesignBlog';
import APIDevelopmentBasicsBlog from './blogs/APIDevelopmentBasicsBlog';
import MachineLearningWebDevBlog from './blogs/MachineLearningWebDevBlog';
import ProductivityTipsForDevsBlog from './blogs/ProductivityTipsForDevsBlogs';
import ImpactOf5GWebDevBlog from './blogs/ImpactOf5GWebDevBlog';
import ServerlessArchitectureBlog from './blogs/ServerlessArchitectureBlog';
import AIForUXBlog from './blogs/AIForUXBlog';
import MobileAppDesignEssentialsBlog from './blogs/MobileAppDesignEssentialBlog';
import ReactServerComponentsBlog from './blogs/ReactServerComponentsBlog';
import ReactPerformanceOptimizationBlog from './blogs/ReactPerformanceOptimizationBlog';
import CreatingCustomReactHooksBlog from './blogs/CustomReactHooksBlog';
import StateManagementReactBlog from './blogs/StateManagementReactBlog';
import TypeScriptReactBlog from './blogs/TypeScriptReactBlog';
import ReactNativeBlog from './blogs/ReactNativeBlog';
import NextJsBlog from './blogs/NextJsBlog';
import StylingReactComponentsBlog from './blogs/StylingReactComponentsBlog';
import ReactTestingBestPracticesBlog from './blogs/ReactTestingBestPracticesBlog';
import ReactDesignPatternsBlog from './blogs/ReactDesignPatternsBlog';
import VoiceSearchOptimizationBlog from './blogs/VoiceSearchOptimizationBlog';
import LocalSEOBlog from './blogs/LocalSEOBlog';
import TechnicalSEOBlog from './blogs/TechnicalSEOBlog';
import ContentMarketing2024Blog from './blogs/ContentMarketingBlog';
import EcommerceSEOBlog from './blogs/EcommerceSEOBlog';
import VideoSEOBlog from './blogs/VideoSEOBlog';
import LinkBuildingStrategies2024Blog from './blogs/LinkBuildingStrategies2024Blog';
import SEOAnalyticsBlog from './blogs/SEOAnalyticsBlog';
import AIinSEOBlog from './blogs/AIinSEOBlog';
import InternationalSEOBlog from './blogs/InternationalSEOBlog';
import WebScrapingGuyBlog from './blogs/WebScrapingGuyBlog';
import About from './pages/About';
import ServicesPage from './pages/ServicesPage';

const AppRoutes = () => {
  return (
    <Routes>
     <Route path="/index.html" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/nftblogs" element={<NFTBlogs />} />
          <Route path="/blogs/mastering-react" element={<MasteringReactBlog />} />
          <Route path="/blogs/seo-checklist" element={<SEOChecklistBlog />} />
          <Route path="/blogs/web-security" element={<SecureWebAppsBlog />} />
          <Route path="/blogs/web-development-trends" element={<FutureWebDevTrendsBlog />} />
          <Route path="/blogs/seo-strategies" element={<SEOStrategiesBlog />} />
          <Route path="/blogs/web-dev-fundamentals" element={<WebDevFundamentalsBlog />} />
          <Route path="/blogs/css-grids" element={<CSSGridsBlog />} />
          <Route path="/blogs/javascript-cheat-sheet" element={<JavaScriptCheatSheetBlog />} />
          <Route path="/blogs/ux-design-principles" element={<UXDesignPrinciplesBlog />} />
          <Route path="/blogs/web-accessibility" element={<WebAccessibilityBlog />} />
          <Route path="/blogs/advanced-css-techniques" element={<AdvancedCSSTechniquesBlog />} />
          <Route path="/blogs/cybersecurity-evolution" element={<CybersecurityThreatsBlog />} />
          <Route path="/blogs/implementing-dark-mode" element={<ImplementingDarkModeBlog />} />
          <Route path="/blogs/basics-of-cryptography" element={<BasicsOfCryptographyBlog />} />
          <Route path="/blogs/intro-to-stoicism" element={<IntroToStoicismBlog />} />
          <Route path="/blogs/fruitarianism-guide" element={<FruitarianismGuideBlog />} />
          <Route path="/blogs/responsive-web-design" element={<ResponsiveWebDesignBlog />} />
          <Route path="/blogs/ethical-hacking-overview" element={<EthicalHackingOverviewBlog />} />
          <Route path="/blogs/minimalist-design" element={<MinimalistDesignBlog />} /> 
          <Route path="/blogs/blockchain-for-web-dev" element={<BlockchainForWebDevBlog />} />
          <Route path="/blogs/web-scraping-python" element={<WebScrapingPythonBlog />} />
          <Route path="/blogs/open-source-software" element={<OpenSourceSoftwareBlog />} />
          <Route path="/blogs/color-theory-web-design" element={<ColorTheoryWebDesignBlog />} />
          <Route path="/blogs/api-development-basics" element={<APIDevelopmentBasicsBlog />} />
          <Route path="/blogs/machine-learning-web-dev" element={<MachineLearningWebDevBlog /> } />
          <Route path="/blogs/productivity-tips-for-devs" element={<ProductivityTipsForDevsBlog />} />
          <Route path="/blogs/impact-of-5g-web-dev" element={<ImpactOf5GWebDevBlog />} />
          <Route path="/blogs/intro-to-serverless" element={<ServerlessArchitectureBlog />} />
          <Route path="/blogs/ai-for-user-experience" element={<AIForUXBlog />} />
          <Route path="/blogs/mobile-app-design-essentials" element={<MobileAppDesignEssentialsBlog />} />
          <Route path="/blogs/react-server-components" element={<ReactServerComponentsBlog />} />
          <Route path="/blogs/react-performance-optimization" element={<ReactPerformanceOptimizationBlog />} />
          <Route path="/blogs/custom-react-hooks" element={<CreatingCustomReactHooksBlog />} />
          <Route path="/blogs/react-state-management" element={<StateManagementReactBlog />} />
          <Route path="/blogs/typescript-react" element={<TypeScriptReactBlog />} />
          <Route path="/blogs/react-native" element={<ReactNativeBlog />} />
          <Route path="/blogs/nextjs" element={<NextJsBlog />} />
          <Route path="/blogs/styling-react-components" element={<StylingReactComponentsBlog />} />
          <Route path="/blogs/react-testing" element={<ReactTestingBestPracticesBlog />} />
          <Route path="/blogs/react-design-patterns" element={<ReactDesignPatternsBlog />} />
          <Route path="/blogs/voice-search-optimization" element={<VoiceSearchOptimizationBlog />} />
          <Route path="/blogs/local-seo" element={<LocalSEOBlog />} />
          <Route path="/blogs/technical-seo" element={<TechnicalSEOBlog />} />
          <Route path="/blogs/content-marketing-2024" element={<ContentMarketing2024Blog />} />
          <Route path="/blogs/ecommerce-seo" element={<EcommerceSEOBlog />} />
          <Route path="/blogs/video-seo" element={<VideoSEOBlog />} />
          <Route path="/blogs/link-building" element={<LinkBuildingStrategies2024Blog />} />
          <Route path="/blogs/seo-analytics" element={<SEOAnalyticsBlog />} />
          <Route path="/blogs/ai-in-seo" element={<AIinSEOBlog />} />
          <Route path="/blogs/international-seo" element={<InternationalSEOBlog />} />
          <Route path="/blogs/web-scraping-guy" element={<WebScrapingGuyBlog />} />
     </Routes>
  );
};

export default AppRoutes;
