import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  InputAdornment,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { Element } from 'react-scroll';

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <Element name="contact">
    <div
    id="contact"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        padding: '50px',
        color: '#fff',
        minHeight: '100vh',
        fontFamily: '"Orbitron", monospace',
      }}
    >
      <Container maxWidth="md">
        {!submitted ? (
          <div
            style={{
              opacity: 1,
              transition: 'opacity 0.7s',
            }}
          >
            <Typography
              variant="h2"
              align="center"
              gutterBottom
              sx={{
                color: '#fff',
                textShadow: '0 0 10px #fff',
                width: '100%',
              }}
            >
              Connect with Neal Frazier Tech Today!
            </Typography>

            <Box
              component="form"
              name="contact"
              method="POST"
              data-netlify="true"
              onSubmit={handleSubmit}
              sx={{
                mt: 4,
                padding: '30px',
                borderRadius: '15px',
                boxShadow: '0px 0px 20px rgba(255, 255, 231, 0.5)',
                backdropFilter: 'blur(10px)',
                border: '1px solid rgba(0, 255, 231, 0.2)',
              }}
            >
              <input type="hidden" name="form-name" value="contact" />

              {/* Name Field */}
              <TextField
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                InputProps={{
                  style: { color: '#fff' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ color: '#fff' }}>👤</span>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#fff',
                    },
                    '&:hover fieldset': {
                      borderColor: '#fff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#fff',
                    },
                  },
                }}
              />

              {/* Email Field */}
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                InputProps={{
                  style: { color: '#fff' },
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ color: '#fff' }}>📧</span>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#fff',
                    },
                    '&:hover fieldset': {
                      borderColor: '#fff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#fff',
                    },
                  },
                }}
              />

              {/* Message Field */}
              <TextField
                fullWidth
                label="Message"
                name="message"
                multiline
                rows={4}
                variant="outlined"
                InputProps={{
                  style: { color: '#fff' },
                }}
                InputLabelProps={{
                  style: { color: '#fff' },
                }}
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#fff',
                    },
                    '&:hover fieldset': {
                      borderColor: '#fff',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#fff',
                    },
                  },
                }}
              />

              {/* Submit Button */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                endIcon={<SendIcon />}
                sx={{
                  mt: 2,
                  background:
                    'linear-gradient(45deg, #fff 30%, #000 90%)',
                  color: '#0f0c29',
                  fontFamily: 'Orbitron, monospace',
                  fontWeight: 'bold',
                  padding: '12px 0',
                  boxShadow: '0px 0px 15px rgba(255, 255, 255, 0.7)',
                  '&:hover': {
                    background:
                      'linear-gradient(45deg, #ffffff 30%, #fff 90%)',
                    boxShadow: '0px 0px 20px rgba(0, 0, 0, 1)',
                  },
                }}
              >
                Send Message
              </Button>
            </Box>
          </div>
        ) : (
          <div
            style={{ textAlign: 'center', opacity: 1, transition: 'opacity 0.7s' }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: 'Orbitron, monospace',
                color: '#fff',
                textShadow: '0 0 10px #fff',
              }}
            >
              Message Sent!
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: '#fff',
                fontFamily: 'Orbitron, monospace',
              }}
            >
              We'll be in touch shortly.
            </Typography>
          </div>
        )}
      </Container>
    </div>
    </Element>
  );
};

export default ContactForm;
