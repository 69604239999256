import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import '../styles/Services.css'; 
import { Parallax } from 'react-scroll-parallax';

const services = [
  {
    title: 'Web Development',
    image: '/assets/WebDevelopment.png',
    description: 'Custom, cutting-edge web development solutions that dominate the digital landscape.',
  },
  {
    title: 'Software Engineering',
    image: '/assets/SoftwareEngineering.png',
    description: 'Expertise in software engineering to transform your business with innovative technology.',
  },
  {
    title: 'Project Management',
    image: '/assets/ProjectManagement.png',
    description: 'Unparalleled project management to ensure your projects are completed efficiently and effectively.',
  },
  {
    title: 'Web Valet Services',
    image: '/assets/WebValet.png',
    description: 'State-of-the-art web valet services that leverage the latest in efficiency and technology.',
  },
  {
    title: 'Cyber Security',
    image: '/assets/CyberSecurity.png',
    description: 'Protecting your business with advanced cyber security solutions and practices.',
  },
  {
    title: 'Data Management',
    image: '/assets/DataManagement.png',
    description: 'Reliable data management services to ensure your data is secure and accessible.',
  },
  {
    title: 'E-Commerce Solutions',
    image: '../assets/ECommerce.png',
    description: 'Comprehensive e-commerce solutions to help you sell online effectively.',
  },
  {
    title: 'Mobile App Development',
    image: '/assets/MobileApp.png',
    description: 'Creating mobile applications that provide an excellent user experience.',
  },
  {
    title: 'AI and Machine Learning',
    image: '/assets/AI.png',
    description: 'Harnessing the power of AI and machine learning to drive business innovation.',
  }
];

const Services = () => {
 
  

  

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      style={{  padding: '50px 0', color: '#fff' }}
    >
      <Container maxWidth="lg">
        <Parallax speed={10} >        
          <Typography 
          id="#services" 
          variant="h2" 
          align="center" 
          gutterBottom
          sx={{ marginBottom: '40px' }}
        >
          Our Services
        </Typography>
        </Parallax>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} key={index}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              >
                <Box
                 
                  className='box' // Using the box class from CSS
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '30px',
                    borderRadius: '10px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
                    height: {xs: '450px', md: '650px'},
                    transition: 'transform 0.3s ease-in-out',
                    justifyContent: 'center',
                    alignContent: 'center',
                    position: 'relative',
                    gap: 3,
                  }}
                >
                  <Box
                    component="img"
                    src={service.image}
                    alt={service.title}
                    sx={{
                      width: {xs: '200px', md: '400px'},
                      height: {xs: '200px', md: '400px'},
                      margin: '0 auto',
                      filter: 'grayscale(100%)', // Apply grayscale filter
                      transition: 'filter 0.3s ease-in-out',
                      '&:hover': {
                        filter: 'grayscale(0%)', // Remove grayscale on hover
                      },
                    }}
                  />
                  <Typography variant="h4" gutterBottom sx={{ color: '#fff' }}>
                    {service.title}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#ccc' }}>
                    {service.description}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </motion.div>
  );
};

export default Services;
